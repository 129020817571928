<template>
  <div>
    <v-container>
      <h3>
        Bienvenidos
      </h3>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
